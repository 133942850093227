import React, { useState } from 'react'

import { getDatoIndexKey } from '@lib/productService/utils'
import ArrowToRightIcon from '@icons/ArrowToRightIcon'
import { IDatoCMSProductListRecord } from '@modules/homepage/interfaces'
import { IProductsBySlug } from '@modules/products/interfaces/productInterfaces'
import Product from './Products/Product'

interface IFeaturedProducts {
  productListRecord: IDatoCMSProductListRecord
  productsBySlug: IProductsBySlug
}

const Products: React.FC<IFeaturedProducts> = ({ productListRecord, productsBySlug }) => {
  const [hideMoreProducts, setHideMoreProducts] = useState(true)

  return (
    <section className="section-spacing section-content-width" id="products">
      <h2 className="section-headline">{productListRecord.headline}</h2>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 w-full mt-8 md:mt-10">
        {productListRecord.products.map((datoCMSProduct, index) => {
          const number = index + 1
          const product = productsBySlug[getDatoIndexKey(datoCMSProduct)]

          if (product) {
            return (
              <Product
                hideMoreProducts={hideMoreProducts}
                key={number}
                number={number}
                product={product}
              />
            )
          }
          return null
        })}
      </div>

      {hideMoreProducts && productListRecord.products.length > 8 && (
        <div className="flex justify-center w-full mt-8 md:mt-16">
          <button
            className="flex bg-black text-white px-5 pt-4 pb-3 font-bold text-sm fullhd:text-tiny uppercase tracking-wider hover:bg-gray-800 items-center"
            onClick={() => setHideMoreProducts(false)}
            type="button">
            <div className="inline-block mr-4 -mt-0.5">
              <ArrowToRightIcon />
            </div>

            <span className="-mt-0.5 inline-block">Mehr Produkte</span>
          </button>
        </div>
      )}
    </section>
  )
}

export default Products
