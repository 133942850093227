import React from 'react'

import Link from 'next/link'
import { Image } from 'react-datocms'

import ArrowToRightIcon from '../../../../icons/ArrowToRightIcon'
import { IDatoCMSEditorialRecord } from '../../interfaces'

interface IEditoral {
  editorialRecord: IDatoCMSEditorialRecord
}

const Editorial: React.FC<IEditoral> = ({ editorialRecord }) => (
  <section className="section-content-width section-spacing">
    <div className="flex flex-wrap w-full">
      <div className="flex w-full md:w-1/2 items-center md:pr-4">
        <Image data={editorialRecord.post.mainImageVideo.responsiveImage} />
      </div>

      <div className="flex w-full md:w-1/2 items-center md:pl-12 pr-4">
        <div className="max-w-xl">
          <h2 className="section-headline block mt-8 md:mt-0 mb-2 md:mb-4">
            {editorialRecord.headline}
          </h2>

          <h3 className="font-extrabold text-3xl md:text-4xl lg:text-4xl 2xl:text-6xl leading-headline">
            {editorialRecord.post.headline}
          </h3>

          <hr className="w-12 border-black border-2 mt-8 md:mt-16 mb-4" />

          <div
            className="text-tiny md:text-base leading-snug md:pb-10"
            dangerouslySetInnerHTML={{ __html: editorialRecord.post.summary }}
          />

          <Link href={`/posts/${editorialRecord.post.slug}`}>
            <a className="inline-flex bg-black text-white mt-8 md:mt-0 px-5 pt-4 pb-3 font-bold text-sm fullhd:text-tiny uppercase tracking-wide hover:bg-gray-800 items-center">
              <div className="inline-block mr-4 -mt-0.5">
                <ArrowToRightIcon />
              </div>

              <span className="-mt-0.5 inline-block">Read the edit</span>
            </a>
          </Link>
        </div>
      </div>
    </div>
  </section>
)

export default Editorial
