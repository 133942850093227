import React from 'react'

import Category from './Categories/Category'
import {
  IDatoCMSCategoryTeaserRecord,
  IDatoCMSCategoryTeaserRecordCategory
} from '../../interfaces'

interface ICategories {
  categoryTeaserRecord: IDatoCMSCategoryTeaserRecord
}

const Categories: React.FC<ICategories> = ({ categoryTeaserRecord }) => {
  const categories = categoryTeaserRecord.categories.map(
    (category: IDatoCMSCategoryTeaserRecordCategory, index) => {
      const key = index + 1

      return <Category category={category} key={key} />
    }
  )

  return (
    <section className="section-content-width section-spacing">
      <h2 className="section-headline">{categoryTeaserRecord.headline}</h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 flex-wrap w-full mt-8 md:-mt-6 md:-mb-8">
        {categories}
      </div>
    </section>
  )
}

export default Categories
