import React from 'react'

import Link from 'next/link'
import { Image } from 'react-datocms'

import ArrowToRightIcon from '../../../../../icons/ArrowToRightIcon'
import { IDatoCMSBrandTeaserRecordBrand } from '../../../interfaces'

interface IBrand {
  brand: IDatoCMSBrandTeaserRecordBrand
  colSpanStyle: string
}

const Brand: React.FC<IBrand> = ({ brand, colSpanStyle }) => (
  <div className={`flex ${colSpanStyle}`}>
    <div className="flex flex-wrap w-full">
      <div className="flex w-full">
        <Image
          className="w-full h-440px"
          data={brand.image.responsiveImage}
          objectFit="cover"
          layout="responsive"
        />
      </div>

      <div className="flex flex-wrap bg-white p-4 md:p-8 items-start h-auto w-full">
        <div className="h-52">
          <div className="flex items-center h-24 w-full">
            <Image
              className="h-24 mb-4"
              pictureClassName="max-w-150px"
              data={brand.brand.logo.responsiveImage}
              objectFit="contain"
              objectPosition="left center"
            />
          </div>

          <p role="doc-subtitle" className="text-tiny md:text-tiny leading-snug">
            {brand.text}
          </p>

          <Link href={brand.link}>
            <a className="flex items-center link-style my-4" href={brand.link} title="Mehr Infos">
              <ArrowToRightIcon />

              <span className="pl-2 group-hover:underline uppercase">Mehr Infos</span>
            </a>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default Brand
