import React, { useState } from 'react'
import ArrowToRightIcon from '@icons/ArrowToRightIcon'
import Button from '@components/shared/Button'
import Popup from '@components/shared/ModalSmall'
import Image from 'next/image'
import { IDatoCMSNewsLetterSignUp } from '@modules/homepage/interfaces'

interface INewsletterSignUp {
  newsletterSignUp: IDatoCMSNewsLetterSignUp
}

const setCookie = () => {
  const cookieName = 'exit_intent'
  const cookieValue = 'true'
  let expirationTime = 2592000
  expirationTime *= 1000
  const today = new Date()
  const todayTimeNow = today.getTime()

  today.setTime(todayTimeNow + expirationTime)
  const date = today.toUTCString()
  document.cookie = `${cookieName}=${cookieValue}; SameSite=None; Secure; expires=${date}; path=/; domain=${window.location.hostname}`
}

const NewsletterSignUp: React.FC<INewsletterSignUp> = ({ newsletterSignUp }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const newsletterImage = newsletterSignUp.link.image
  const onClick = () => {
    setCookie()
    setIsPopupOpen(true)
  }
  return (
    <section className="w-full md:mb-4">
      <div className="relative md:items-end py-32 2xl:py-48 ">
        <Image
          src={newsletterImage.responsiveImage.src}
          layout="fill"
          objectFit="cover"
          placeholder="blur"
          blurDataURL={newsletterImage.responsiveImage.base64}
          alt={newsletterImage.responsiveImage.alt}
        />
        <div className="relative w-full h-full ">
          <div className="flex w-full h-full justify-center md:justify-end items-center section-content-width">
            <div className="flex justify-center items-center w-full md:w-3/5 lg:w-1/2 p-12 bg-white">
              <div className="h-full flex flex-col items-start justify-start flex-wrap bg-white ">
                <h2 className="font-extrabold text-base md:text-lg mb-6 uppercase">
                  {newsletterSignUp.link.headline}
                </h2>
                <p className="font-bold text-base md:text-tiny lg:text-lg mb-6">
                  {newsletterSignUp.link.text}
                </p>
                <Button
                  backgroundColor="relative bg-black md:hover:opacity-75 md:hover:shadow-lg bottom-0"
                  textColor="text-white"
                  onClick={onClick}>
                  <div className="inline-block mr-4 -mt-0.5">
                    <ArrowToRightIcon />
                  </div>
                  <span className="inline-block">
                    {newsletterSignUp.link.callToAction[0].buttonText}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <Popup
          content={
            <>
              <div className="max-w-content-sm mx-auto md:pt-10">
                <iframe
                  title="newsletter"
                  className="mj-w-res-iframe h-520px sm:h-440px"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                  src={newsletterSignUp.link.callToAction[0].externalLink}
                  width="100%"
                  height="480"
                />

                <script
                  type="text/javascript"
                  src={newsletterSignUp.link.callToAction[0].externalLink}
                />
              </div>
            </>
          }
          handleClose={() => setIsPopupOpen(!isPopupOpen)}
        />
      )}
    </section>
  )
}

export default NewsletterSignUp
