import React, { createRef, useRef, useState } from 'react'
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/outline'

interface ICarousel {
  carouselItems: JSX.Element[]
  slidesToShowMobile: 1 | 2
  slidesToShowDesktop: 1 | 2 | 3 | 4
  // eslint-disable-next-line react/require-default-props
  gap?: 0 | 4
  width: 'full' | 'trimmed'
  // eslint-disable-next-line react/require-default-props
  showAllOnDesktop?: boolean
}
const Carousel: React.FC<ICarousel> = ({
  carouselItems,
  slidesToShowMobile,
  slidesToShowDesktop,
  gap = 4,
  width,
  showAllOnDesktop = false
}) => {
  const [activeCarouselItemIndex, setActiveCarouselItemIndex] = useState(0)
  const scrollAreaRef = useRef<HTMLDivElement>()
  const carouselItemRefs = useRef([])
  carouselItemRefs.current = carouselItems.map((_, i) => carouselItemRefs.current[i] ?? createRef())

  const scrollToElementByIndex = (index) => {
    const left = carouselItemRefs.current[index].current.offsetLeft

    scrollAreaRef.current.scrollTo({
      left,
      behavior: 'smooth'
    })
  }

  const checkIfElementIsInViewOfContainer = (container, element): boolean => {
    const isInViewOffset = element.offsetWidth * 0.05
    if (container && element) {
      // Get container properties
      const cLeft = container.scrollLeft
      const cRight = cLeft + container.offsetWidth

      // Get element properties
      const eLeft = element.offsetLeft
      const eRight = eLeft + element.offsetWidth - isInViewOffset

      // Check if in view
      return eLeft >= cLeft && eRight <= cRight
    }
    return false
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollElement = event.currentTarget
    carouselItems.forEach((carouselItem, index) => {
      const elm = carouselItemRefs.current[index].current
      if (checkIfElementIsInViewOfContainer(scrollElement, elm)) {
        setActiveCarouselItemIndex(index)
      }
    })
  }

  const switchCarouselItem = (itemIndex) => {
    let index = itemIndex
    if (index >= carouselItems.length) index = 0
    if (index < 0) index = carouselItems.length - 1
    scrollToElementByIndex(index)
  }

  const mobileSlideWidth = (): string => {
    if (width !== 'full')
      return slidesToShowMobile === 1 ? 'w-[calc(100%_-_2rem)]' : 'w-[calc(50%_-_0.5rem)]'
    return 'w-full'
  }
  const desktopSlideWidth = width === 'full' ? 'md:w-full' : ''
  const hiddenOnDesktopIfNotNecessary =
    slidesToShowDesktop >= carouselItems.length || showAllOnDesktop ? 'md:hidden' : ''
  const hiddenOnMobileIfNotNecessary = slidesToShowMobile >= carouselItems.length ? 'hidden' : ''

  return (
    <div className="-mx-4 relative">
      <div className="w-full">
        <div
          className={`no-scrollbar w-full flex snap-x snap-mandatory overflow-hidden overflow-x-scroll ${
            showAllOnDesktop ? 'md:flex-wrap' : ''
          }`}
          ref={scrollAreaRef}
          onScroll={handleScroll}>
          {carouselItems.map((carouselItem, index) => {
            const key = `carouselItem-${index + 1}`

            return (
              <div
                key={key}
                ref={carouselItemRefs.current[index]}
                className={`relative snap-always snap-start flex-shrink-0 pl-${gap} ${mobileSlideWidth()} md:w-1/${slidesToShowDesktop} md:p-${gap} ${desktopSlideWidth}`}>
                {carouselItem}
              </div>
            )
          })}
          {/* the only way to get padding to the right of the last element (safari mobile) */}
          <div className={`pr-${gap} md:pr-0`} />
        </div>
        <div className="m-auto text-center">
          {carouselItems.map((item, index) => {
            const key = `navItem-${index + 1}`

            return (
              // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
              <button
                key={key}
                type="button"
                className={`slider-nav-item ${
                  index === activeCarouselItemIndex ? 'slider-nav-item__active' : ''
                } ${hiddenOnDesktopIfNotNecessary} ${hiddenOnMobileIfNotNecessary}`}
                onClick={() => scrollToElementByIndex(index)}
              />
            )
          })}
        </div>
      </div>
      <button
        type="button"
        className={`absolute left-0 -ml-3 top-1/2 rounded-full bg-gray-200 p-2 absolute z-10 hidden md:block ${hiddenOnDesktopIfNotNecessary} text-black hover:text-white hover:bg-black`}
        onClick={() => {
          switchCarouselItem(activeCarouselItemIndex - 1)
        }}>
        <ArrowLeftIcon className="w-5 h-5" />
      </button>
      <button
        type="button"
        className={`absolute right-0 -mr-3 top-1/2 rounded-full bg-gray-200 p-2 absolute z-10 hidden md:block ${hiddenOnDesktopIfNotNecessary} text-black hover:text-white hover:bg-black`}
        onClick={() => {
          switchCarouselItem(activeCarouselItemIndex + 1)
        }}>
        <ArrowRightIcon className="w-5 h-5" />
      </button>
    </div>
  )
}

export default Carousel
