import { toEuro } from '../../../utils'
import { IProduct, IProductVariant } from '../interfaces/productInterfaces'

export interface IPrices {
  fromPrice: string | null
  price: string
  regularPrice: string | null
}

const formatPrice = (price: string): string => toEuro(parseFloat(price))

function getFromPrice(product: IProduct): string | null {
  const variants: IProductVariant[] = Object.values(product.variantsBySKU)

  if (variants.length < 1) {
    return null
  }

  const variantPrices: number[] = variants.map((variant: IProductVariant) =>
    parseFloat(variant.price)
  )

  const lowestPrice = Math.min(...variantPrices)

  return `ab ${formatPrice(lowestPrice.toString())}`
}

function getPrice(product: IProduct, variantSKU: string): string | null {
  const variant = product.variantsBySKU[variantSKU]

  const price = variant ? variant.price : product.price

  return formatPrice(price)
}

function getRegularPrice(product: IProduct, variantSKU: string): string | null {
  const variant = product.variantsBySKU[variantSKU]
  const price = variant ? variant.price : product.price
  const regularPrice = variant ? variant.regularPrice : product.regularPrice

  return regularPrice && regularPrice !== price ? formatPrice(regularPrice) : null
}

const getPrices = ({ product, variantSKU }): IPrices => {
  const fromPrice = getFromPrice(product)
  const price = getPrice(product, variantSKU)
  const regularPrice = getRegularPrice(product, variantSKU)

  return {
    fromPrice,
    price,
    regularPrice
  }
}

export default getPrices
