import { IShopifyProduct } from '@lib/shopify/interfaces'
import { IDatoCMSProduct } from '@modules/products/interfaces/datoCMSProductInterfaces'

export const findShopifyProductByHandle = (
  datoProduct: IDatoCMSProduct,
  shopifyProducts: IShopifyProduct[]
): IShopifyProduct | null =>
  shopifyProducts.find((shopifyProduct) => shopifyProduct.handle === datoProduct.shopifyProductId)

export const getDatoIndexKey = (datoProduct: IDatoCMSProduct): string =>
  datoProduct.shopifyProductId
