import React from 'react'

import Brand from './Brands/Brand'
import { IDatoCMSBrandTeaserRecord } from '../../interfaces'

interface IBrands {
  brandTeaserRecord: IDatoCMSBrandTeaserRecord
}

const Brands: React.FC<IBrands> = ({ brandTeaserRecord }) => {
  const brands = brandTeaserRecord.brands.map((brand, index) => {
    const colSpanStyle = index === 0 ? 'xl:col-span-3' : 'xl:col-span-2'
    const key = index + 1

    return <Brand brand={brand} colSpanStyle={colSpanStyle} key={key} />
  })

  return (
    <section className="w-full py-8 md:py-8 lg:py-16 xl:py-16 2xl:py-32 bg-gray-100">
      <div className="section-content-width">
        <h2 className="section-headline">{brandTeaserRecord.headline}</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-7 gap-6 fullhd+:gap-10 flex-wrap w-full mt-8 md:mt-10">
          {brands}
        </div>
      </div>
    </section>
  )
}

export default Brands
