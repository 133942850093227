import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { IDatoCMSCategoryTeaserRecordCategory } from '../../../interfaces'

interface ICategory {
  category: IDatoCMSCategoryTeaserRecordCategory
}

const Category: React.FC<ICategory> = ({ category }) => {
  const leftImage = category.images[0]
  const rightImage = category.images[1]

  return (
    <Link href={`/categories/${category.category.slug}`}>
      <a className="group flex relative items-center mt-0 md:mt-16 mb-0 md:mb-16 md:hover:mt-8 md:hover:mb-8 transition-all cursor-pointer">
        <div className="mt-0 md:group-hover:mt-8 w-1/2 transition-all w-1/2 relative">
          <Image
            className="w-full h-full"
            src={leftImage.responsiveImage.src}
            alt={leftImage.alt}
            width={374}
            height={550}
            placeholder="blur"
            layout="intrinsic"
            blurDataURL={leftImage.responsiveImage.base64}
          />
        </div>
        <div className="mt-0 md:group-hover:-mt-8 w-1/2 transition-all w-1/2 relative">
          <Image
            className="w-full h-full"
            src={rightImage.responsiveImage}
            alt={rightImage.alt}
            width={374}
            height={550}
            layout="intrinsic"
            blurDataURL={rightImage.responsiveImage.base64}
            placeholder="blur"
          />
        </div>
        <div className="absolute w-full text-center">
          <div className="md:group-hover:text-white md:group-hover:bg-black mx-auto text-center w-fit bg-white text-base lg:text-lg uppercase tracking-wider font-bold text-black py-2 px-4">
            <span className="block mt-0.5">{category.category.name}</span>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default Category
