import React from 'react'
import Image from 'next/image'
import { IDatoCMSImage } from '../../../../common/interfaces/datoCMSInterfaces'
import { IDatoCMSCategory } from '../../../interfaces'

interface IExpertSingle {
  id: string
  image: IDatoCMSImage
  categories: IDatoCMSCategory[]
  name: string
  shortDescription: string
}

const ExpertSingle: React.FC<IExpertSingle> = ({
  id,
  image,
  categories,
  name,
  shortDescription
}) => {
  const categoriesExpertHeadline = `${categories
    .map((authorCategory) => authorCategory.name)
    .join(', ')} Experte`

  return (
    <section
      id={id}
      className="scroll-offset flex flex-col max-w-content-sm mx-4 my-16 p-4 bg-gray-200 lg:flex-row lg:items-center lg:mx-auto lg:rounded-br-lg lg:rounded-tl-lg lg:p-12">
      <div className="w-3/5 aspect-square self-center -mt-10 mb-6 lg:w-1/2 lg:my-0 lg:w-3/12 flex-shrink-0">
        <Image
          src={image.responsiveImage}
          layout="fill"
          objectFit="cover"
          objectPosition={`${image.focalPoint.x * 100}% ${image.focalPoint.y * 100}%`}
          placeholder="blur"
          blurDataURL={image.responsiveImage.base64}
          className="rounded-full"
        />
      </div>
      <div className="flex flex-col lg:px-12 lg:w-4/12 flex-shrink-0">
        <h3 className="text-sm font-bold uppercase">{categoriesExpertHeadline}</h3>
        <p className="text-2xl font-bold leading-tight mt-1 mb-3 lg:pb-0">{name}</p>
      </div>
      <div className="lg:w-5/12">
        <div dangerouslySetInnerHTML={{ __html: shortDescription }} className="text-tiny" />
        {/* <Link href={`/experts/${author.slug}`}>Mehr</Link> */}
      </div>
    </section>
  )
}

export default ExpertSingle
