import React from 'react'
import Carousel from '@modules/common/components/Carousel'
import { IDatoCMSExpertRecommendationRecord } from '@modules/category/interfaces'
import { IProductsBySlug } from '@modules/products/interfaces/productInterfaces'
import Expert from '@modules/category/components/ContentSections/ExpertRecommendation/Expert'
import ProductWithRecommendationText from '@modules/category/components/ContentSections/ExpertRecommendation/ProductWithRecommendationText'

interface IExpertRecommendation {
  productRecommendation: IDatoCMSExpertRecommendationRecord
  productsBySlug: IProductsBySlug
}

const ExpertRecommendation: React.FC<IExpertRecommendation> = ({
  productRecommendation,
  productsBySlug
}) => {
  const carouselRecommendationProducts = productRecommendation.products.map((product, index) => (
    <>
      <ProductWithRecommendationText
        key={product.id}
        productWithRecommendationText={product}
        number={index + 1}
        productsBySlug={productsBySlug}
      />
    </>
  ))

  return (
    <>
      {/* Mobile Version */}
      <section className="section-spacing section-content-width">
        <h2 className="section-headline mb-8">{productRecommendation.headline}</h2>
        <div className="md:hidden">
          <Expert productRecommendation={productRecommendation} />
          <Carousel
            carouselItems={carouselRecommendationProducts}
            slidesToShowMobile={1}
            slidesToShowDesktop={4}
            width="trimmed"
          />
        </div>
        <div className="-mx-4 hidden md:block">
          {/* Desktop Version */}
          <div className="flex flex-wrap md:flex-row md:px-1">
            <Expert productRecommendation={productRecommendation} />
            {carouselRecommendationProducts}
          </div>
        </div>
      </section>
    </>
  )
}

export default ExpertRecommendation
