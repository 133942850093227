import React from 'react'

import ExpertSlide from '@modules/homepage/components/ContentSections/Experts/ExpertSlide'
import { IDatoCMSExpertTeaserRecord } from '../../interfaces'
import ExpertSingle from './Experts/ExpertSingle'
import Carousel from '../../../common/components/Carousel'

interface IExperts {
  expertTeaserRecord: IDatoCMSExpertTeaserRecord
}

const Experts: React.FC<IExperts> = ({ expertTeaserRecord }) => {
  const carouselItems: JSX.Element[] = expertTeaserRecord.experts.map((expert) => (
    <ExpertSlide expertTeaserRecordExpert={expert} key={expert.expert.name} />
  ))

  return (
    <>
      {expertTeaserRecord.experts.length !== 1 && (
        <section className="section-content-width section-spacing">
          <h2 className="section-headline mb-8 md:mb-10">{expertTeaserRecord.headline}</h2>
          <Carousel
            carouselItems={carouselItems}
            slidesToShowDesktop={3}
            slidesToShowMobile={1}
            width="trimmed"
            gap={4}
          />
        </section>
      )}
      {expertTeaserRecord.experts.length === 1 && (
        <ExpertSingle
          id={expertTeaserRecord.experts[0].expert.slug}
          categories={expertTeaserRecord.experts[0].expert.categories}
          image={expertTeaserRecord.experts[0].image}
          name={expertTeaserRecord.experts[0].expert.name}
          shortDescription={expertTeaserRecord.experts[0].text}
        />
      )}
    </>
  )
}

export default Experts
