import React from 'react'
import Image from 'next/image'
import { IProductsBySlug } from '@modules/products/interfaces/productInterfaces'
import { IDatoCMSThematicRecommendationRecord } from '@modules/contentSections/interfaces'
import { IDatoCMSProduct } from '@modules/products/interfaces/datoCMSProductInterfaces'
import Product from '@modules/homepage/components/ContentSections/Products/Product'
import Carousel from '@modules/common/components/Carousel'
import { getDatoIndexKey } from '@lib/productService/utils'

interface IThematicRecommendation {
  thematicRecommendation: IDatoCMSThematicRecommendationRecord
  productsBySlug: IProductsBySlug
}

const ThematicRecommendation: React.FC<IThematicRecommendation> = ({
  thematicRecommendation,
  productsBySlug
}) => {
  const carouselItems = thematicRecommendation.products.map(
    (datoCMSProduct: IDatoCMSProduct, index: number) => {
      const number = index + 1
      const product = productsBySlug[getDatoIndexKey(datoCMSProduct)]

      return <Product number={number} hideMoreProducts={false} product={product} />
    }
  )
  return (
    <section className="section-spacing section-content-width">
      <div className="flex flex-wrap column md:row pb-8">
        <div className="w-full md:w-1/2 md:pr-4">
          <Image
            layout="responsive"
            width={280}
            height={200}
            placeholder="blur"
            blurDataURL={thematicRecommendation.image.responsiveImage.base64}
            src={thematicRecommendation.image.responsiveImage}
          />
        </div>

        <div className="w-full md:w-1/2 flex items-center justify-center md:pl-12 pr-4">
          <div className="max-w-xl">
            <h2 className="section-headline block mt-8 md:mt-0 mb-2 md:mb-4">
              {thematicRecommendation.overline}
            </h2>

            <h3 className="font-extrabold text-3xl md:text-4xl lg:text-4xl 2xl:text-6xl leading-headline">
              {thematicRecommendation.headline}
            </h3>

            <div className="text-tiny md:text-base leading-snug mt-8 md:pb-10">
              {thematicRecommendation.text}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Carousel
          carouselItems={carouselItems}
          slidesToShowMobile={1}
          slidesToShowDesktop={4}
          width="trimmed"
          showAllOnDesktop
        />
      </div>
    </section>
  )
}

export default ThematicRecommendation
