import React from 'react'
import Image from 'next/image'
import { getDatoIndexKey } from '@lib/productService/utils'
import Product from '@modules/homepage/components/ContentSections/Products/Product'
import { IProductsBySlug } from '@modules/products/interfaces/productInterfaces'
import { IDatoCMSProductRecommendation } from '@modules/category/interfaces'

interface IProductWithRecommendationText {
  number: number
  productWithRecommendationText: IDatoCMSProductRecommendation
  productsBySlug: IProductsBySlug
}

const ProductWithRecommendationText: React.FC<IProductWithRecommendationText> = ({
  number,
  productWithRecommendationText,
  productsBySlug
}) => {
  const product = productsBySlug[getDatoIndexKey(productWithRecommendationText.product)]
  return (
    <div className="md:w-1/4 md:px-3">
      <div className="text-left">
        <Product hideMoreProducts={false} number={number} product={product} />
      </div>
      <div className="flex">
        <div className="w-full">
          <div className="mb-8 text-left">
            <blockquote className="block text-left md:text-tiny leading-snug">
              <div className="pr-2 inline-block">
                <Image
                  objectFit="contain"
                  alt="yoself Icon"
                  width="21"
                  height="15"
                  src="/images/quote_icon.svg"
                />
              </div>
              <span>{productWithRecommendationText.text}</span>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductWithRecommendationText
