import React from 'react'

import { Image } from 'react-datocms'

import { IDatoCMSMoodImageRecord } from '../../interfaces'

interface IMoodImage {
  moodImageRecord: IDatoCMSMoodImageRecord
}

const MoodImage: React.FC<IMoodImage> = ({ moodImageRecord }) => (
  <section className="w-full mb-8 md:mb-24 fullhd:mb-32">
    <div className="relative flex w-full h-90vh">
      <Image
        data={moodImageRecord.image.responsiveImage}
        layout="fill"
        objectFit="cover"
        objectPosition={`${moodImageRecord.image.focalPoint.x * 100}% ${
          moodImageRecord.image.focalPoint.y * 100
        }%`}
        // pictureClassName="object-cover h-full md:h-auto w-auto md:w-full"
      />

      {/* <img */}
      {/*  src="/images/fullscreen-test.jpg" */}
      {/*  className="object-cover h-full md:h-auto w-auto md:w-full" */}
      {/*  alt="stage left" */}
      {/*  width="100%" */}
      {/*  height="100%" */}
      {/* /> */}
    </div>

    <div className="flex flex-wrap px-4 py-4 lg:px-8 fullhd+:px-4 items-start h-auto fullhd+:max-w-fullhd mx-auto md:mt-2">
      <div className="w-full">
        <p
          role="doc-subtitle"
          className="text-tiny md:text-base xl:text-lg md:mt-0.5 leading-snug font-bold">
          {moodImageRecord.quote}
        </p>
      </div>
    </div>
  </section>
)

export default MoodImage
