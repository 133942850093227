import Image from 'next/image'
import React from 'react'
import { IDatoCMSExpertRecommendationRecord } from '../../../interfaces'

interface IExpert {
  productRecommendation: IDatoCMSExpertRecommendationRecord
}

const Expert: React.FC<IExpert> = ({ productRecommendation }) => (
  <div className="w-full flex md:w-1/2 md:px-3 mb-8">
    <div className="relative flex flex-wrap w-full rounded-br-lg md:rounded-none">
      <div className="relative flex w-full md:w-1/2 justify-center items-center md:pr-3">
        <div className="block w-full h-[70vw] md:h-full">
          <Image
            objectFit="cover"
            objectPosition={`
              ${productRecommendation.image.focalPoint.x * 100}% 
              ${productRecommendation.image.focalPoint.y * 100}%
            `}
            placeholder="blur"
            blurDataURL={productRecommendation.image.responsiveImage.base64}
            layout="fill"
            src={productRecommendation.image.responsiveImage}
            className="rounded-tl-lg md:rounded-none"
          />
        </div>
      </div>
      <div className="flex flex-wrap py-4 w-full md:w-1/2 px-4 items-center bg-gray-200 mb-8">
        <div className="flex flex-wrap w-full justify-center">
          <div className="-mt-10 w-12 h-12 bg-gray-200 rounded-full z-10 p-1 md:mt-0">
            <Image alt="yoself Icon" width="50" height="50" src="/images/yoself_icon_black.png" />
          </div>
          <p className="w-full text-black text-sm whitespace-pre-wrap font-bold leading-snug md:text-base md:mt-8 pb-4 md:pb-2 lg:px-6">
            {productRecommendation.text}
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Expert
