import React from 'react'

import Image from 'next/image'

// import ArrowToRightIcon from '../../../../../icons/ArrowToRightIcon'
// import PlayIcon from '../../../../../icons/PlayIcon'
import { IDatoCMSExpertTeaserRecordExpert } from '../../../interfaces'

interface IExpertSlide {
  expertTeaserRecordExpert: IDatoCMSExpertTeaserRecordExpert
}

const ExpertSlide: React.FC<IExpertSlide> = ({ expertTeaserRecordExpert }) => (
  <>
    {/* <a className="group" href="mehr" title="Mehr zur Expertin"> */}
    <div className="flex flex-wrap group w-full">
      {/* NOTE: Keeping this as a reference for later. It is the expert image with a video play button overlay, which we are currently not supporting. */}

      {/* <div className="flex w-full relative"> */}
      {/*  /!* Video Button *!/ */}
      {/*  <div className="flex justify-center items-center absolute bottom-0 right-0 h-16 w-16 mb-4 mr-4 p-4 pr-3 xl:mb-6 xl:mr-8 lg:p-5 lg:pr-4 bg-black text-white hover:bg-white hover:text-black rounded-full z-10"> */}
      {/*    <PlayIcon /> */}
      {/*  </div> */}

      {/*  <Image */}
      {/*    alt={expertTeaserRecordExpert.image.alt} */}
      {/*    className="w-full h-auto md:group-hover:scale-105 transform transition-all" */}
      {/*    height="750" */}
      {/*    objectFit="cover" */}
      {/*    src={expertTeaserRecordExpert.image.url} */}
      {/*    width="600" */}
      {/*  /> */}
      {/* </div> */}

      <div className="flex w-full">
        <Image
          src={expertTeaserRecordExpert.image.responsiveImage}
          width={720}
          height={900}
          placeholder="blur"
          blurDataURL={expertTeaserRecordExpert.image.responsiveImage.base64}
          className="w-full h-auto md:group-hover:scale-105 transform transition-all"
        />
      </div>

      <div className="flex flex-wrap mt-4 md:mt-6">
        <h3 className="font-bold text-base xl:text-lg w-full text-left">
          {expertTeaserRecordExpert.expert.name}
        </h3>

        <div
          role="doc-subtitle"
          className="text-tiny md:text-tiny leading-snug pr-4 text-left"
          dangerouslySetInnerHTML={{ __html: expertTeaserRecordExpert.text }}
        />

        {/* <div className={`flex w-full items-center ${linkStyle} my-4`} title="Mehr zur Expertin"> */}
        {/*  <ArrowToRightIcon /> */}

        {/*  <span className="pl-2 group-hover:underline">Mehr Infos</span> */}
        {/* </div> */}
      </div>
    </div>
    {/* </a> */}
  </>
)

export default ExpertSlide
