import React from 'react'

import Link from 'next/link'
import Image from 'next/image'
import { IProduct } from '@modules/products/interfaces/productInterfaces'
import getPrices from '@modules/products/lib/prices'

interface IProductComponent {
  hideMoreProducts: boolean
  number: number
  product: IProduct
}

const Product: React.FC<IProductComponent> = ({ hideMoreProducts, number, product }) => {
  if (hideMoreProducts && number > 8) {
    return null
  }

  const { fromPrice, price, regularPrice } = getPrices({ product, variantSKU: null })

  const renderedPrice = () => {
    if (regularPrice === null || regularPrice === price) {
      return <span className="text-sm md:text-base leading-snug">{fromPrice || price}</span>
    }

    return (
      <p className="text-sm md:text-base leading-snug">
        <span className="text-gray-400 line-through">{regularPrice}</span>

        <span className="text-red-500 ml-4">{price}</span>
      </p>
    )
  }

  return (
    <Link href={`/products/${product.slug}`}>
      <a className="group">
        <div className="w-full relative">
          <div className="aspect-w-4 aspect-h-3 bg-gray-200 group-hover:bg-gray-300">
            <Image
              className="md:group-hover:scale-105 transition-all"
              src={product.images[0].url}
              alt={product.images[0].alt}
              quality={60}
              layout="fill"
              objectFit="cover"
              objectPosition={`${product.images[0].focalPoint.x * 100}% ${
                product.images[0].focalPoint.y * 100
              }%`}
            />
          </div>
          {regularPrice && regularPrice !== price && (
            <div className="absolute top-0 left-0 m-2 md:m-4 py-1 px-2 uppercase tracking-wider bg-black text-white text-xs font-bold z-10">
              Deal
            </div>
          )}

          <div className="my-4">
            <span className="uppercase text-xs md:text-sm tracking-wide">{product.brand.name}</span>

            <h4 className="font-bold text-sm md:text-base w-full group-hover:underline">
              {product.name}
            </h4>
            {renderedPrice()}
          </div>
        </div>
      </a>
    </Link>
  )
}

export default Product
