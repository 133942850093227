import React from 'react'
import Brands from '@modules/homepage/components/ContentSections/Brands'
import {
  IDatoCMSBrandTeaserRecord,
  IDatoCMSCategoryTeaserRecord,
  IDatoCMSEditorialRecord,
  IDatoCMSExpertTeaserRecord,
  IDatoCMSMoodImageRecord,
  IDatoCMSNewsLetterSignUp,
  IDatoCMSProductListRecord
} from '@modules/homepage/interfaces'
import Categories from '@modules/homepage/components/ContentSections/Categories'
import Editorial from '@modules/homepage/components/ContentSections/Editorial'
import Experts from '@modules/homepage/components/ContentSections/Experts'
import MoodImage from '@modules/homepage/components/ContentSections/MoodImage'
import ThematicRecommendation from '@modules/contentSections/components/ThematicRecommendation'
import { IDatoCMSThematicRecommendationRecord } from '@modules/contentSections/interfaces'
import Products from '@modules/homepage/components/ContentSections/Products'
import ExpertRecommendation from '@modules/category/components/ContentSections/ExpertRecommendation'
import { IDatoCMSExpertRecommendationRecord } from '@modules/category/interfaces'
import { IDatoCMSContentSection } from '@modules/common/interfaces/datoCMSInterfaces'
import { IProductsBySlug } from '@modules/products/interfaces/productInterfaces'
import NewsletterSignUp from '@modules/homepage/components/NewsletterSignUp'

const getContentSectionList = (
  contentSections: IDatoCMSContentSection[],
  productsBySlug: IProductsBySlug
): JSX.Element[] =>
  contentSections.map((contentSection) => {
    // eslint-disable-next-line no-underscore-dangle
    const modelApiKey = contentSection._modelApiKey

    switch (modelApiKey) {
      case 'brand_teaser':
        return <Brands brandTeaserRecord={contentSection as IDatoCMSBrandTeaserRecord} />
      case 'category_teaser':
        return <Categories categoryTeaserRecord={contentSection as IDatoCMSCategoryTeaserRecord} />
      case 'editorial':
        return <Editorial editorialRecord={contentSection as IDatoCMSEditorialRecord} />
      case 'expert_teaser':
        return <Experts expertTeaserRecord={contentSection as IDatoCMSExpertTeaserRecord} />
      case 'mood_image':
        return <MoodImage moodImageRecord={contentSection as IDatoCMSMoodImageRecord} />
      case 'thematic_recommendation':
        return (
          <ThematicRecommendation
            thematicRecommendation={contentSection as IDatoCMSThematicRecommendationRecord}
            productsBySlug={productsBySlug}
          />
        )
      case 'product_list':
        return (
          <Products
            productListRecord={contentSection as IDatoCMSProductListRecord}
            productsBySlug={productsBySlug}
          />
        )
      case 'expert_recommendation':
        return (
          <ExpertRecommendation
            productsBySlug={productsBySlug}
            productRecommendation={contentSection as IDatoCMSExpertRecommendationRecord}
          />
        )
      case 'newsletter_teaser':
        return <NewsletterSignUp newsletterSignUp={contentSection as IDatoCMSNewsLetterSignUp} />

      default:
        throw new Error(`Component for _modelApiKey: ${modelApiKey} not implement yet!`)
    }
  })

export default getContentSectionList
